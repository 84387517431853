<template>
  <div>
    <!-- begin breadcrumb -->
    <ol class="breadcrumb pull-right">
      <li class="breadcrumb-item active">
        <a href="javascript:">{{ $t('Onboard') }}</a>
      </li>
    </ol>
    <!-- end breadcrumb -->
    <!-- begin page-header -->
    <h1 class="page-header">
      {{ $t('Onboard') }}
      <small> {{ $t('header small text goes here...') }}</small>
    </h1>
    <!-- end page-header -->
    <panel title="Panel title here">
      <!--            <p>-->
      <!--                {{ $t('Panel content here') }}-->
      <!--            </p>-->

      <!--            <button v-on:click="checkForm">Test</button>-->

      <service-points-selector />
    </panel>
  </div>
</template>
<script>
import { defineAsyncComponent } from 'vue'
// import { UserService } from '../../services'
// import Centrifuge from 'centrifuge'

// var centrifuge = new Centrifuge('wss://centrifugo.orderadmin.eu/connection/websocket', {
//   debug: true,
//   subscribeEndpoint: 'https://alpha.orderadmin.eu/a.php',
//   subscribeHeaders: {
//     'Access-Control-Allow-Origin': '*'
//   },
//   subscribeParams: {}
// });

// centrifuge.setToken('eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJzdWIiOiJtQGlxY29tcGFueS5ydSIsIm5hbWUiOiJNYXhpbSJ9.5XO8gKLbHMTuIaE4_rDUj_f4uBYrudLjWihu5uO022w')

export default {
  components: {
    ServicePointsSelector: defineAsyncComponent(() => import('../../components/delivery-services/ServicePointsSelector.vue'))
  },
  data: function () {
    return {
    }
  },
  computed: {
  },
  created () {
    this.$centrifuge.subscribe('$gossips', function (message) {
      console.log(message)
    })
  },
  mounted: function () {
  },
  methods: {
    checkForm: function (e) {
      console.log(this.$centrifuge)

      this.$centrifuge.publish('$gossips', {'input': 'hi'}).then(function (res) {
        console.log('successfully published')
      }, function (err) {
        console.log('publish error', err)
      })
      //
      // centrifuge.publish("public:news", {"input": "hello"}).then(function(res) {
      //   console.log('successfully published');
      // }, function(err) {
      //   console.log('publish error', err);
      // });
    }
  }
}
</script>
